

import Footer from '../components/Footer'
import AboutUsinfo from '../components/AboutUsinfo';



export default function AboutUsPage() {



  return (
    <>
      <AboutUsinfo />
      <Footer />
    </>
  );

}